import React from "react"
// - import { Link } from "gatsby"
// +
import Link from "../components/link"
import  {FeatureImage}  from '../components/Image';
import {ThemeContext, Box, Heading, Paragraph, Text } from "grommet"
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react'
import Layout from "../components/layout"
import SEO from "../components/seo"

import { map, size } from 'lodash';
const _ = require("lodash")

const myPar = {
  paragraph: {
    extend: `
      font-weight: 300;`
  }
};

const myParTop = {
  paragraph: {
    extend: `
      font-weight: 700;`
  }
};

const components = {

  p: Paragraph,
}

const IndexPage = ({ data, pageContext }) => {
  const { post, coverImageUrl } = data;

  const { featuredImage, title, subtitle, author, date,category, breadCrumbs } = post.frontmatter;

  return (

  <Layout>
    <SEO title="Home" />

    <Box  width="large" align="center" margin={{ top: 'large' }}>

      <Box direction="row" >
        {map(breadCrumbs, ({ url, label }, idx) => (
            <Link margin={{ horizontal: 'small' }} to={url}>{label}</Link>
        ))}
      </Box>

      <Heading textAlign="center"  margin={{ vertical: 'large' }}>{post.frontmatter.title}</Heading>

    </Box>

    <Box align="start" width="large"  margin={{ vertical: 'medium' }}>
        <ThemeContext.Extend value={myPar}>
           <MDXProvider components={components}>
             <MDXRenderer>{post.body}</MDXRenderer>
             </MDXProvider>
        </ThemeContext.Extend>
    </Box>
  </Layout>

);
};

export const query = graphql`
  query($id: String!) {
    post: mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        breadCrumbs{
          url
          label
        }
      }
    }


  }
`;
export default IndexPage
